body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
}

@media only screen and (min-width: 2500px){
  html {
    font-size: 2em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickable:hover {
  cursor: pointer;
}

.scalable {
  transition: width .4s ease, max-width .5s ease, padding .5s ease;
}

/* EFFECTS */

/* Box shadow */
.elementSelectable {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.elementSelectable:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.elementSelectable:hover .dont-hover {
  cursor: default; 
}

.elementSelectable.selected {
  background-color: rgba(0, 116, 141, 0.12);
}

.elementSelectable.disabled:hover {
  cursor: default; 
  background-color: #FFFFFF;
}

/* Box image triangle cut */ 
.boxImageTriangle {
  -webkit-mask:
      linear-gradient(-260deg,#fff 90%,transparent 50%) top right;
  -webkit-mask-size:100% 100%; /* width = height and big enough to consider all the cases */
  -webkit-mask-repeat:no-repeat;
  -webkit-mask-composite: destination-in;
  mask:
      linear-gradient(-260deg,#fff 90%,transparent 50%) top right;
  mask-size:100% 100%; /* width = height and big enough to consider all the cases */
  mask-repeat:no-repeat;
  mask-composite: intersect;

  margin:5px;
}

.boxImageTriangleTV {
  -webkit-mask:
      linear-gradient(-260deg,#fff 90%,transparent 50%) top right;
  -webkit-mask-size:200% 100%; /* width = height and big enough to consider all the cases */
  -webkit-mask-repeat:no-repeat;
  -webkit-mask-composite: destination-in;
  mask:
      linear-gradient(-260deg,#fff 90%,transparent 50%) top right;
  mask-size:200% 100%; /* width = height and big enough to consider all the cases */
  mask-repeat:no-repeat;
  mask-composite: intersect;
}

/** Animations **/
.animate {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.bold {
  font-weight: 500;
}
